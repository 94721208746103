import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Divider, Grid } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { Fab, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import Loading from '../loading/Loading';


export const Grupos = () => {
    const navigate = useNavigate();
    const [grupos, setGrupos] = useState();
    const [reload, setReload] = useState(false);


    useEffect(() => {
        const getDataGrupos = async () => {
            try {
                let dataGrupos = await axios.get('grupos/');
              
                dataGrupos = dataGrupos.data.items;
                // console.log("dataGrupos", dataGrupos)
                setGrupos(dataGrupos)
                setReload(true)
            } catch (error) {
                console.log(error)
            }
            
        }
        getDataGrupos();
    }, [reload])

    function alertaConfirmacion () {
        let resConfirmar = window.confirm("¿ Seguro desea eliminar el item ?");
        return resConfirmar;
    }

    async function eliminarGrupo (e) {
        let confirmacion = alertaConfirmacion();

        if(confirmacion === true) {
            await axios.delete('grupos', {data: {id: e}})
            alert("Grupo eliminado")
            setReload(false)
        }
        
        return
    }

    function nuevoGrupoRedirect () {
        navigate('nuevo')
    }

    return grupos ? (
        <div>
            
            <Grid container justify="flex-end" margin={4} >
                <Fab color="primary" aria-label='add' onClick={nuevoGrupoRedirect} variant="extended" >
                    <AddIcon sx={{ mr: 1 }} />
                    Nuevo Grupo
                </Fab>
                <Typography variant='h3' align="center" sx={{ flexGrow: 1, mr: 34 }} >
                    Grupos
                </Typography>
            </Grid>
            <Divider/>
            <TableContainer component={Paper}>
         
                <Table sx={{ minWidth: 650 }} aria-label="Grupos">
                    <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell align="center">Tag</TableCell>
                        <TableCell align="right">Acciones</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {grupos.map((grupo) => (
                        <TableRow
                        key={grupo.nombre}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {grupo.nombre}
                        </TableCell>
                        <TableCell align="center">
                            {grupo.tag}
                        </TableCell>

                        <TableCell align="right">
                            <Button component={NavLink} to={`${grupo.tag}`}>
                                <SearchIcon/>
                            </Button>
                            <Button component={NavLink} to={`modificar/${grupo.tag}`}>
                                <EditIcon/>
                            </Button>
                            <Button 
                                variant="text" 
                                type='button' 
                                color="error"
                                onClick={() => eliminarGrupo(grupo.idGrupo)} 
                                >
                                    <DeleteIcon />
                                </Button>
                        </TableCell>

                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : (
        <Loading/>
    );
}
