import React, { useState, useEffect } from "react";
import ItemMonitor from "../itemMonitor/ItemMonitor";
import ItemMonitorMobile from "../itemMonitorMobile/ItemMonitorMobile";
import "./Monitor.css";

import mostrarAlerta from "../../hooks/mostrarAlerta";

import { obtenerDatosApi } from "../../hooks/obtenerDatosApi";
import { obtenerDatosNovedades } from "../../hooks/obtenerDatosNovedades";
import Loading from "../loading/Loading";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const style = makeStyles({
    root: {
        background:
            "radial-gradient(circle, rgba(230,234,233,1) 17%, rgba(218,218,218,1) 88%)",
        pointerEvents: "stroke",
        touchAction: "pan-y",
    },
});

const Monitor = () => {
    const classes = style();

    const [graph, setGraph] = useState();
    const [loading, setLoading] = useState(false);
    const [variable, setVariable] = useState("");

    let screenMedia = {
        width: "600px",
        height: "290px",
        margin: false,
    };

    let boxSize = {
        rows: "5",
        cols: "170",
    };

    function getScreenMedia() {
        let windowSize = window.screen;
        // console.log("windowSize", windowSize);
        if (windowSize.width > 600) {
            let screenWidth = Math.floor(windowSize.width / 3) + "px";
            screenMedia.width = screenWidth + "px";
        } else {
            screenMedia.width = windowSize.width - 10;
            boxSize.cols = "45";
            screenMedia.margin = true;
        }
        if (windowSize.width < 1200 && boxSize.cols !== "45")
            return (boxSize.cols = "100");
    }

    getScreenMedia();

    const options = {
        autoResize: true,
        groups: {
            principal: {
                color: "#00d471",
                shape: "circle",
                widthConstraint: 80,
                heightConstraint: 2,
                margin: 10,
                radius: 80,
                borderWidth: 5,
                fontSize: 32,
                scaling: {
                    label: {
                        enabled: true,
                        min: 10,
                        max: 20,
                    },
                },
                value: 1,
            },
            hijo: {
                widthConstraint: 90,
                heightConstraint: 2,
                fontSize: 24,
            },
        },
        layout: {
            randomSeed: 80,
            improvedLayout: true,
            hierarchical: {
                enabled: false,
                levelSeparation: 100,
                nodeSpacing: 50,
                treeSpacing: 50,
                blockShifting: true,
                edgeMinimization: true,
                parentCentralization: false,
                direction: "DU", // UD, DU, LR, RL
                sortMethod: "directed", // hubsize, directed
            },
        },
        physics: false,
        edges: {
            color: "#000000",
            smooth: false,
        },
        height: screenMedia.height.toString(),
        width: screenMedia.width.toString(),
        interaction: {
            zoomView: false,
            dragNodes: false,
            dragView: false,
        },
    };

    const event = {
        click: function (element) {
            // console.log("click", element)
            mostrarAlerta(element.nodes[0]);
        },
    };

    const token = window.localStorage.getItem("token");

    useEffect(() => {
        const getData = async () => {
            try {
                const dataNovedades = await obtenerDatosNovedades();
                setVariable(dataNovedades);

                const datos = await obtenerDatosApi(token);

                window.localStorage.setItem("token", datos.token);

                if (datos.moment.length !== 0) {
                    setGraph(datos.moment);
                    setLoading(true);
                    // console.log(datos)
                }
            } catch (err) {
                console.error("rompe", err);
            }
        };

        const interval = setInterval(() => {
            getData();
        }, 5000);

        return () => clearInterval(interval);
    }, [graph, token, variable]);

    return loading === true ? (
        screenMedia.margin === false ? (
            <div className="monitor-container">
                <div className="monitor-box">
                    {graph.map((e, i) => {
                        return (
                            <Paper
                                key={i}
                                variant="elevation"
                                className={classes.root}
                                elevation={1}
                                sx={{
                                    mt: screenMedia.margin !== false ? 6 : 2,
                                    mx: 1,
                                }}
                            >
                                <ItemMonitor
                                    graph={graph[i]}
                                    options={options}
                                    event={event}
                                    key={i}
                                />
                            </Paper>
                        );
                    })}
                </div>

                <div className="monitor-text">
                    <textarea
                        name="text-area"
                        value={variable}
                        rows={boxSize.rows}
                        cols={boxSize.cols}
                        props="true"
                        readOnly
                    ></textarea>
                </div>
            </div>
        ) : (
            <div className="monitor-box">
                {graph.map((e, i) => {
                    return (
                        <ItemMonitorMobile key={i} data={graph[i]}/>
                    )
                    
                })}
            </div>
        )
    ) : (
        <Loading />
    );
};

export default Monitor;
