import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import axios from 'axios';

import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField
  } from '@mui/material';
import Loading from '../loading/Loading';
import moment from 'moment';


export const NotificacionesDetalle = () => {

    const params = useParams();
    const [notificacionDetalle, setNotificacionDetalle] = useState();
    const [date, setDate] = useState('')
    // moment.locale("es-419");
    // moment.defaultFormat = "DD.MM.YYYY HH:mm";
    
    useEffect(() => {
        const getDataNotificacionDetalle = async () => {
            try {
                let res = await axios.get('notificaciones/id/' + params.id)
                res = res.data.items
                // console.log("res detalle noti", res)
                setNotificacionDetalle(res[0])
                // setDate(new Date(Date.parse(res[0].fecha,"dd/mm/yyyy hh:mm:ss")))
                setDate(moment(res[0].fecha).format('DD/MM/YYYY HH:mm:ss'))
            } catch (error) {
                console.log(error)
            }
            
        }
        getDataNotificacionDetalle();
    }, [params])


    return notificacionDetalle && date ? (
        < >
            {/* <Typography variant='h3' align="center" sx={{mb:2}} >
                Detalle Notificacion
            </Typography> */}

            <Card>
                <CardHeader
                title={`ID:  ${params.id}`}
                />
                <Divider />
                <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Nombre"
                        name="nombre"
                        defaultValue={notificacionDetalle.Item.nombre}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Fecha"
                        name="fecha"
                        defaultValue={date}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Estado"
                        name="estado"
                        defaultValue={notificacionDetalle.Estado.nombre}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={12}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Mensaje"
                        name="mensaje"
                        defaultValue={notificacionDetalle.mensaje? notificacionDetalle.mensaje : "Sin Mensaje"}
                        variant="standard"
                        multiline
                        maxRows={5}
                    />
                    </Grid>
                    <Grid
                    item
                    md={12}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Log"
                        name="log"
                        defaultValue={notificacionDetalle.log}
                        variant="standard"
                        multiline
                        
                    />
                    </Grid>
                </Grid>
                </CardContent>
            <Divider />
            
            <Divider />

        </Card>
        </>
    ) : (
        <Loading/>
    )
}
