import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';

import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { CardContent, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';

import Loading from '../loading/Loading';

export const ItemsNuevo = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [grupos, setGrupos] = useState();

    const [nuevoItem, setNuevoItem] = useState({
        nombre: '',
        tag: '',
        tamanio: 8,
        habilitado: '',
        url_chequeo: '',
        relacion: [],
        afecta_padre: '',
        estado: '1'
    })

    const [nuevaRelacion, setNuevaRelacion] = useState([]);
    const [inputCheck, setInputCheck] = useState(true);
    const [errors, setErrors] = useState({});

    // function alertaConfirmacion () {
    //     let resConfirmar = window.confirm("¿ Desea agregar otro item ?");
    //     return resConfirmar;
    // }

    async function crearNuevoItem () {
        let checkHabilitado = inputCheck === true ? 1 : 0
        let bodyCrearItem = {
            nombre: nuevoItem.nombre,
            tag: nuevoItem.tag,
            habilitado: checkHabilitado,
            tamanio: nuevoItem.tamanio,
            url_chequeo: nuevoItem.url_chequeo,
            estado: nuevoItem.estado,
            afecta_padre: 1,
            relacion: nuevoItem.relacion
        }
        console.log("body crear", bodyCrearItem)
        let resCrear = await axios.post('items', bodyCrearItem)
        console.log("res modificar" , resCrear)
        if(resCrear.data.codigo_respuesta === 0) {
            if(resCrear.data.items === 'tagDuplicado') {
                alert("El TAG ya existe!")
                return false
            }
            alert("Algo salio mal")
            return false
        } else {
            alert("Se creo exitosamente el item " , resCrear.data.items.nombre);
            navigate('/backoffice/items');
            return true
        }
    }

    function validarCampos (input) {
        let errors = {};

        let regexTag = /^[a-zA-Z-0-9]+$/;
        let regexUrl = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;

        if(!input.nombre.trim()) {
            errors.nombre = " * Nombre es requerido"
        }

        if(!input.tag.trim()) {
            errors.tag = " * Tag es requerido"
        } else if(!regexTag.test(input.tag.trim())) {
            errors.tag = " * El campo TAG no permite caracteres especiales ni espacios!"
        }

        if(!input.url_chequeo.trim()) {
            errors.url_chequeo = " * Tag es requerido"
        } else if(!regexUrl.test(input.url_chequeo.trim())) {
            errors.url_chequeo = " * Debe ser una URL valida"
        }

        if(!input.url_chequeo.trim()) {
            errors.url_chequeo = " * URL es requerido"
        }

        return errors;
    }

    function handleOnBlur (e) {
        setErrors(validarCampos(nuevoItem))
    }

    function handleSubmit(e){
        e.preventDefault();
        
        let resCrear = crearNuevoItem(nuevoItem);
        if(resCrear === true) {
            navigate('/backoffice/items');
            return
            } 
        return
    }

    function handleInputChange(e) {
        setNuevoItem({
            ...nuevoItem,
            [e.target.name]: e.target.value
        })
    }

    function handleInputChangeCheck(e){
        setInputCheck(!inputCheck)
    }

    function handleSelectChange(e){
        setNuevoItem({
            ...nuevoItem,
            tamanio: e.target.value
        })
    }

    function handleSelectChangeEstadoNuevo(e){
        setNuevoItem({
            ...nuevoItem,
            estado: e.target.value
        })
    }

    function handleSelectChangeGrupo(e, i) {
        setNuevaRelacion([
            ...nuevaRelacion,
            {
                id: e.target.value,
                nombre: i.props.name
            }
        ])
        setNuevoItem({
            ...nuevoItem,
            relacion: [...nuevoItem.relacion, e.target.value]
        })

    }

    function eliminarNuevaRelacion(e) {
        let newData = nuevaRelacion.filter(n => n !== e)
        setNuevaRelacion(newData)
    }

    // function reload () {
    //     setNuevoItem({
    //         nombre: '',
    //         tag: '',
    //         tamanio: 8,
    //         habilitado: '',
    //         url_chequeo: '',
    //         relacion: [],
    //         afecta_padre: '',
    //         estado: '1'
    //     });
    //     setInputCheck()
    //     setNuevaRelacion([])
    // }

    useEffect(() => {
        const getData = async () => {
            try {
                let allGrupos = await axios.get('grupos');
                allGrupos = allGrupos.data.items;

                setGrupos(allGrupos);
                setLoading(true)
            } catch (error) {
                console.log(error)
            }
            
        }
        getData();
    }, [loading])


    return loading ? (
        <>
            <Typography variant='h3' align="center" sx={{mb:2}} >
                Nuevo Item
            </Typography>
            <Divider/>
            < >
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardHeader 
                        title="Crear nuevo Item"
                        />
                        <Divider />
                        <CardContent>
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    
                    >
                            <Grid 
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                name="nombre"
                                label="Nombre"
                                type="text"
                                value={nuevoItem.nombre}
                                placeholder="Ingrese un Nombre" 
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                autoComplete="off"
                                fullWidth={true} 
                                
                                helperText={errors.nombre && errors.nombre}
                                error={errors.nombre && true}
                            />
                            </Grid>
                            <Grid 
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                label="TAG"
                                type="text" 
                                name="tag" 
                                placeholder="Ingrese un TAG" 
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                value={nuevoItem.tag} 
                                className="input"
                                autoComplete="off"
                                fullWidth={true} 
                                
                                helperText={errors.tag && errors.tag}
                                error={errors.tag && true}
                            />
                            </Grid>
                            <Grid 
                            item
                            md={12}
                            xs={12}
                            >
                            <TextField
                                label="URL_Chequeo"
                                type="text" 
                                name="url_chequeo" 
                                placeholder="Ingrese la URL de chequeo" 
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                value={nuevoItem.url_chequeo} 
                                className="input"
                                autoComplete="off"
                                fullWidth={true} 
                                
                                helperText={errors.url_chequeo && errors.url_chequeo}
                                error={errors.url_chequeo && true}
                            />
                            </Grid>
                        
                            <Grid
                            item
                            md={6}
                            xs={12}
                            
                            >
                                <FormControl
                                sx={{display:"flex", alignItems: "flex-start", textAlign: "initial"}}
                                >
                                <FormLabel>Tamaño</FormLabel>
                                <Select
                                name="tamanio"
                                value={nuevoItem.tamanio}
                                onChange={handleSelectChange}
                                defaultValue={8}
                                fullWidth={true}
                                
                                >
                                <MenuItem key={8} value="8">
                                    8
                                </MenuItem>
                                <MenuItem key={10} value="10">
                                    10
                                </MenuItem>
                                <MenuItem key={12} value="12">
                                    12
                                </MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            
                            
                            <Grid 
                            item
                            md={6}
                            xs={12}
                            
                            >
                            <FormControl
                            sx={{display:"flex",alignItems: "flex-start", textAlign: "initial"}}
                            >
                                <FormLabel>Estado</FormLabel>
                                <Select
                                name="estado"
                                value={nuevoItem.estado}
                                onChange={handleSelectChangeEstadoNuevo}
                                defaultValue={1}
                                fullWidth={true}
                                
                                >
                                <MenuItem key={1} value="1">
                                    OK
                                </MenuItem>
                                <MenuItem key={2} value="2">
                                    Warning
                                </MenuItem>
                                <MenuItem key={3} value="3">
                                    Error
                                </MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            
                            <Grid item
                            
                            md={12}
                            xs={12}
                        
                            sx={{display:"flex", margin: 1}}
                            >
                            <FormControl>
                                <FormLabel>Habilitado</FormLabel>
                                <RadioGroup
                                
                                name="check" 
                                onChange={handleInputChangeCheck}
                                value={inputCheck}
                                fullWidth={true}
                                
                                row
                                >
                                <FormControlLabel
                                    key="true"
                                    value="true"
                                    control={<Radio size="small" />}
                                    label="Si"
                                    defaultChecked={inputCheck}
                                />
                                <FormControlLabel
                                    key="false"
                                    value="false"
                                    control={<Radio size="small" />}
                                    label="No"
                                    defaultChecked={inputCheck}
                                />
                                </RadioGroup>
                            </FormControl>
                            </Grid>

                        <Divider/>
                <Card
                sx={{m: 2, textAlign: 'left', alignItems: 'left', width: '100%'}}
                
                >
                    <CardHeader 
                    title="Relaciones"
                    
                    />
                            <FormControl
                            sx={{ m: 2, width: '50ch', alignItems: 'left' }}
                            >
                                <FormLabel
                                    
                                >
                                    Agregar Relacion</FormLabel>
                                    <Select
                                    lebel="Grupo"
                                    name="grupo"
                                    lebelid="relaciones"
                                    onChange={handleSelectChangeGrupo}
                                    labelId="grupo"
                                    >
                                        {grupos.map((e,i) => {
                                            return (
                                                <MenuItem key={`${i}`} value={e.idGrupo} name={e.nombre}>
                                                    {e.nombre}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                            </FormControl>
                            {nuevaRelacion.map((e,i) => {
                                return (
                                                                      
                                    <CardContent>
                                        <Grid container >
                                            <Grid>
                                                <Box 
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                <Typography
                                                    color="textPrimary"
                                                    gutterBottom
                                                    variant="h6"
                                                    >
                                                    {e.nombre}
                                                </Typography> 
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider/>
                                        <CardActions>
                                            <Button
                                                color="error"
                                                variant="text"
                                                type="button"
                                                onClick={() => eliminarNuevaRelacion(e)}
                                            >
                                                Quitar
                                            </Button>
                                        </CardActions>
                                    </CardContent>
                                    )
                            })}
                    <Divider />
                    
                </Card>   
                        <Grid 
                            container 
                            alignItems="space-between" 
                            justify="center" 
                            direction="row" 
                            margin={2}
                            spacing={2}
                        >
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit" 
                                    disabled={errors.nombre || errors.tag || errors.url_chequeo ? true : false}
                                    >
                                    Crear
                                </Button>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    </CardContent>
                    </Card>
                </form>   
            </>
        </>
    ) : (
        <Loading />
    )
};
