import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper'
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Loading from '../loading/Loading';

export const Notificaciones = () => {
    const [notificaciones, setNotificaciones] = useState()
   
    useEffect(() => {
        const getData = async () => {
            try {
                let res = await axios.get('notificaciones');
                res = res.data.items
                let modelRows = generarModeloRows(res)
                setNotificaciones(modelRows)
            } catch (error) {
                console.log(error)
            }
            
        }
        getData();
    }, [])

    function generarModeloRows (elementos) {
        let modelo = elementos.map((e) => {
            let fechaFormato = moment(e.fecha).format('DD/MM/YYYY HH:mm:ss')
            return {
                id: e.idNotification,
                nombre: e.Item.nombre,
                fecha: fechaFormato,
                estado: e.Estado.nombre,
                mensaje: e.mensaje
            }
        })
        return modelo;
      }

    const columns = [
        { field: 'id', headerName: 'ID', width: 60, hide: true},
        { field: 'nombre', headerName: 'Item', width: 200 },
        { field: 'fecha', headerName: 'Fecha', width: 160 },
        { field: 'estado', headerName: 'Estado'},
        { field: 'mensaje', headerName: 'Mensaje', flex: 1, minWidth: 200, disableReorder: true},
        { field: 'ver', headerName: '', width: 50, disableReorder: true, renderCell: (params) => {
            return (
                <NavLink to={`${params.id}`}><SearchIcon/></NavLink>
            )
        }},
    ];
      

  return notificaciones? (
    <Paper>
        <Typography variant='h3' align="center" >
            Notificaciones
        </Typography>
        <Divider/>
        <div style={{ width: '100%' }}>
            <DataGrid
            rows={notificaciones}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            disableColumnMenu 
            autoHeight
            />
        </div>
    </Paper>
  ) : (
    <Loading />
  );
}