import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Divider, Typography, CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import Box from '@mui/material/Box';
// import CardActions from '@mui/material/CardActions';
import Loading from '../loading/Loading';


export const GruposNuevo = () => {
    
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    // const [items, setItems] = useState();

    const [nuevoGrupo, setNuevoGrupo] = useState({
        nombre: '',
        tag: '',
        habilitado: ''
    })

    const [inputCheck, setInputCheck] = useState()

    const [errors, setErrors] = useState({})

    useEffect(() => {
        const getData = async () => {
            try {
                
                // let allItems = await axios.get('items');
                // allItems = allItems.data.items;

                // setItems(allItems);
                setLoading(true)
                return
                
            } catch (error) {
                console.log(error)
            }
            
        }
        getData();
    }, [loading]);

    async function crearNuevoGrupo () {
        let checkHabilitado = inputCheck === true ? 1 : 0
        let bodyCrearGrupo = {
            nombre: nuevoGrupo.nombre,
            tag: nuevoGrupo.tag,
            habilitado: checkHabilitado,
            orden: 1
        }
        console.log("body crear", bodyCrearGrupo)
        let resCrearGrupo = await axios.post('grupos', bodyCrearGrupo)
        console.log("res modificar" , resCrearGrupo)
        if(resCrearGrupo.data.codigo_respuesta === 0) {
            if(resCrearGrupo.data.items === 'tagDuplicado') {
                alert("El TAG ya existe!")
                return false
            }
            alert("Algo salio mal")
            return false
        } else {
            alert("Se creo exitosamente el item " , resCrearGrupo.data.items.nombre);
            navigate('/backoffice/grupos');
            return true
        }
    }

    function validarCampos (input) {
        let errors = {};

        let regexTag = /^[a-zA-Z-0-9]+$/;
    
        if(!input.nombre.trim()) {
            errors.nombre = " * Nombre es requerido"
        }

        if(!input.tag.trim()) {
            errors.tag = " * Tag es requerido"
        } else if(!regexTag.test(input.tag.trim())) {
            errors.tag = " * El campo TAG no permite caracteres especiales ni espacios!"
        }

        return errors;
    }

    function handleOnBlur (e) {
        setErrors(validarCampos(nuevoGrupo))
    }

    function handleSubmit(e){
        e.preventDefault();

        let resCrear = crearNuevoGrupo(nuevoGrupo);
        if(resCrear === true) {
            navigate('/backoffice/grupos');
            return
            } 
        return
    }

    function handleInputChange(e) {
        setNuevoGrupo({
            ...nuevoGrupo,
            [e.target.name]: e.target.value
        })
    }

    function handleInputChangeCheck(e){
        setInputCheck(!inputCheck)
    }

// Relaciones --- Para crear relaciones se deb revisar api ABM grupos

    // function handleSelectChangeGrupo(e, i) {
    //     // console.log("e", e)
    //     // console.log("i", i.props.name)
    //     setNuevaRelacionItem([
    //         ...nuevaRelacionItem,
    //         {
    //             id: e.target.value,
    //             nombre: i.props.name
    //         }
    //     ])
    //     // console.log("nuevaRel", nuevaRelacion)
    // }

    // function eliminarNuevaRelacion(e) {
    //     let newData = nuevaRelacionItem.filter(n => n !== e)
    //     setNuevaRelacionItem(newData)
    // }

    // async function agregarRelacionItem (nuevaRel) {

    //     async function agregar(relacion) {
    //         await axios.post('relaciones', relacion)
    //         // console.log(resAgregarRelacionItem)
    //         return
    //     }

    //     let dataNuevaRel = nuevaRel.map((e) => {
    //         return {
    //             idGrupo: grupoDetalle.idGrupo, 
    //             afecta_padre: 1, 
    //             idItem: e.id
    //         }
    //     })

    //     dataNuevaRel.forEach(relacion => agregar(relacion))
        
        
    // }



    return loading ? (
        <>
            <Typography variant='h3' align="center" sx={{mb:2}} >
                Nuevo Grupo
            </Typography>
            <Divider/>
            <>
                <form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader 
                    title="Crear nuevo Grupo"
                    />
                    <Divider />
                        <CardContent>
                <Grid 
                    container 
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    >
                            <Grid 
                                item
                                md={6}
                                xs={12}
                            >
                            <TextField
                                name="nombre"
                                label="Nombre"
                                type="text"
                                onChange={handleInputChange}
                                value={nuevoGrupo.nombre} 
                                autocomplete="off"
                                fullWidth
                                onBlur={handleOnBlur}
                                helperText={errors.nombre && errors.nombre}
                                error={errors.nombre && true}   
                            />
                            </Grid>
                            <Grid 
                                item
                                md={6}
                                xs={12}
                            >
                            <TextField
                                label="TAG"
                                type="text" 
                                name="tag" 
                                onChange={handleInputChange}
                                value={nuevoGrupo.tag} 
                                autocomplete="off"
                                fullWidth
                                onBlur={handleOnBlur}
                                helperText={errors.tag && errors.tag}
                                error={errors.tag && true}  
                            />
                            </Grid>
                        
                            <Grid 
                                item
                                md={12}
                                xs={12}
                                sx={{display:"flex", margin: 1}}
                            >
                            <FormControl>
                                <FormLabel>Habilitado</FormLabel>
                                <RadioGroup
                                name="check" 
                                onChange={handleInputChangeCheck}
                                value={inputCheck}
                                
                                row
                                >
                                <FormControlLabel
                                    key="true"
                                    value="true"
                                    control={<Radio size="small" />}
                                    label="Si"
                                    defaultChecked={inputCheck}
                                />
                                <FormControlLabel
                                    key="false"
                                    value="false"
                                    control={<Radio size="small" />}
                                    label="No"
                                    defaultChecked={inputCheck}
                                />
                                </RadioGroup>
                            </FormControl>
                            </Grid>
                        <Divider />

                        {/* <Card 
                        sx={{ m: 2 , textAlign: 'left', alignItems: 'left', width: '100%'}}
                        
                        >
                    <CardHeader 
                    title="Relaciones"
                    /> 
                        <FormControl
                        sx={{ m: 2, width: '50ch', alignItems: 'left' }}
                        >
                            <FormLabel>
                                Agregar Relacion</FormLabel>
                                <Select
                                lebel="Grupo"
                                name="grupo"
                                lebelId="1"
                                onChange={handleSelectChangeGrupo}
                                
                                >
                                    {items.map((e,i) => {
                                        return (
                                            <MenuItem key={i} value={e.idItem} name={e.nombre}>
                                                {e.nombre}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                        </FormControl>
                        {nuevaRelacionItem.map((e,i) => {
                            return (
                                <CardContent>
                                    <Grid container>
                                        <Grid>
                                            <Box 
                                            sx={{
                                                // some styles
                                                ":hover": {
                                                boxShadow: 1,
                                                },
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                            >
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h6"
                                                >
                                                    {e.nombre}
                                            </Typography>                                         
                                            </Box>  
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                    <CardActions>
                                        <Button
                                            color="error"
                                            variant="text"
                                            type="button"
                                            onClick={() => eliminarNuevaRelacion(e)}
                                        >
                                            Quitar
                                        </Button>
                                    </CardActions>
                                </CardContent>                               
                                )
                        })}
                    <Divider />
                </Card> */}
                <Divider />
                    <Grid 
                        container 
                        alignItems="space-between" 
                        justify="center" 
                        direction="row" 
                        margin={1}
                        spacing={2}
                    >
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"
                                disabled={errors.nombre || errors.tag ? true : false}
                                >
                                Guardar Cambios
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                    </CardContent>
                    </Card>
                </form>
            </>
        </>
    ) : (
        <Loading />
    )
}
