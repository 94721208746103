import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import App from './App.jsx';
import subscription from './main';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
window.localStorage.removeItem('token');

ReactDOM.render(
    <App />
  ,
  document.getElementById('root')
);

subscription();