import {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';

import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Divider, Typography, CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Loading from '../loading/Loading';


export const GruposModificar = () => {
    const params = useParams();
    const navigate = useNavigate();
    
    const [reload, setReload] = useState(false);

    const [grupoDetalle, setGrupoDetalle] = useState();
    const [items, setItems] = useState();
    const [nuevaRelacionItem, setNuevaRelacionItem] = useState([]);
    const [eliminarRelacion, setEliminarRelacion] = useState([]);
    
    const [input, setInput] = useState({
        nombre: '',
        tag: '',
        orden: '',
        id: ''
    })

    const [inputCheck, setInputCheck] = useState()
    const [errors, setErrors] = useState({})

    useEffect(() => {
        const getDataGrupoDetalle = async () => {
            try {
                let grupoTagDetalle = await axios.get('grupos/' + params.tag);
                grupoTagDetalle = grupoTagDetalle.data.items;
                
                let allItems = await axios.get('items');
                allItems = allItems.data.items;

                setItems(allItems);

                setGrupoDetalle(grupoTagDetalle);

                setInput({
                    nombre: grupoTagDetalle.nombre,
                    tag: grupoTagDetalle.tag,
                    orden: grupoTagDetalle.orden,
                    id: grupoTagDetalle.id
                });

                if(grupoTagDetalle.habilitado === 1){
                    setInputCheck(true)
                } else {
                    setInputCheck(false)
                }
                
                setReload(true)
                return



            } catch (error) {
                console.log(error)
            }
            
        }
        getDataGrupoDetalle();
    }, [params, reload]);

    async function modificarGrupo () {
        let checkHabilitado = inputCheck === true ? 1 : 0
        let bodyModificacion = {
            nombre: input.nombre,
            tag: input.tag,
            habilitado: checkHabilitado,
            id: grupoDetalle.idGrupo
        }
        // console.log("body modificar", bodyModificacion)
        await axios.put('grupos', bodyModificacion)
        await agregarRelacionItem(nuevaRelacionItem)
        await quitarRelacion(eliminarRelacion)
        // console.log(resModificar)
        return true
    }

    async function handleSubmit(e){
        e.preventDefault();
        // dispatch a ruta de back
        // console.log("input", input)
        let res = await modificarGrupo(input)
        if(res === true) {
            alert("Item Modificado")
            navigate(-1, {replace: true})
        }
        
        return
    }

    function handleInputChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleInputChangeCheck(e){
        setInputCheck(!inputCheck)
        // console.log("hadle", e.target.value)
        
    }

    // function handleSelecteChangeItem(e) {
    //     setNuevaRelacionItem(e.target.value)

    // }

    async function quitarRelacion (idCards) {

        async function eliminar(idCard) {
            await axios.delete('relaciones', {data: {id: idCard}});
            return
        }
        
        idCards.forEach(relacion => eliminar(relacion))
        return
    }

    // async function agregarRelacion () {
    //     let dataNuevaRel = {
    //         idGrupo: grupoDetalle.idGrupo,
    //         afecta_padre: 1,
    //         idItem: nuevaRelacionItem
    //     };

    //     await axios.post('relaciones', dataNuevaRel);
        
    //     alert("Agregado")
    //     setReload(false)
    //     return
    // }

    function eliminarRelacionExistente(e) {
        setEliminarRelacion([
            ...eliminarRelacion, e
        ])
        let relacionesModificadas = grupoDetalle.Relaciones.filter(r => r.idRelacion !== e)
        
        setGrupoDetalle({
            ...grupoDetalle,
            Relaciones: relacionesModificadas
        })
    }

    function handleSelectChangeGrupo(e, i) {
        // console.log("e", e)
        // console.log("i", i.props.name)
        setNuevaRelacionItem([
            ...nuevaRelacionItem,
            {
                id: e.target.value,
                nombre: i.props.name
            }
        ])
        // console.log("nuevaRel", nuevaRelacion)
    }

    function eliminarNuevaRelacion(e) {
        let newData = nuevaRelacionItem.filter(n => n !== e)
        setNuevaRelacionItem(newData)
    }

    async function agregarRelacionItem (nuevaRel) {

        async function agregar(relacion) {
            await axios.post('relaciones', relacion)
            // console.log(resAgregarRelacionItem)
            return
        }

        let dataNuevaRel = nuevaRel.map((e) => {
            return {
                idGrupo: grupoDetalle.idGrupo, 
                afecta_padre: 1, 
                idItem: e.id
            }
        })

        dataNuevaRel.forEach(relacion => agregar(relacion))
        
        
    }

    function validarCampos (input) {
        let errors = {};

        let regexTag = /^[a-zA-Z-0-9]+$/;
    
        if(!input.nombre.trim()) {
            errors.nombre = " * Nombre es requerido"
        }

        if(!input.tag.trim()) {
            errors.tag = " * Tag es requerido"
        } else if(!regexTag.test(input.tag.trim())) {
            errors.tag = " * El campo TAG no permite caracteres especiales ni espacios!"
        }

        return errors;
    }

    function handleOnBlur (e) {
        
        setErrors(validarCampos(input))
    }


    return reload ? (
        <>
            <Typography variant='h3' align="center" sx={{mb:2}} >
                Modificar Grupo
            </Typography>
            <Divider/>
                <form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader 
                    title={grupoDetalle.nombre}
                    />
                    <Divider />
                        <CardContent>
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    >
                            <Grid 
                                item
                                md={6}
                                xs={12}
                            >
                            <TextField
                                name="nombre"
                                label="Nombre"
                                type="text"
                                placeholder={grupoDetalle.nombre} 
                                onChange={handleInputChange}
                                value={input.nombre} 
                                autocomplete="off"
                                fullWidth
                                onBlur={handleOnBlur}
                                helperText={errors.nombre && errors.nombre}
                                error={errors.nombre && true}   
                            />
                            </Grid>
                            <Grid 
                                item
                                md={6}
                                xs={12}
                            >
                            <TextField
                                label="TAG"
                                type="text" 
                                name="tag" 
                                placeholder={grupoDetalle.tag} 
                                onChange={handleInputChange}
                                value={input.tag} 
                                autocomplete="off"
                                fullWidth
                                onBlur={handleOnBlur}
                                helperText={errors.tag && errors.tag}
                                error={errors.tag && true}  
                            />
                            </Grid>
                        
                            <Grid 
                                item
                                md={12}
                                xs={12}
                                sx={{display:"flex", margin: 1}}
                            >
                            <FormControl>
                                <FormLabel>Habilitado</FormLabel>
                                <RadioGroup
                                name="check" 
                                onChange={handleInputChangeCheck}
                                value={inputCheck}
                                
                                row
                                >
                                <FormControlLabel
                                    key="true"
                                    value="true"
                                    control={<Radio size="small" />}
                                    label="Si"
                                    defaultChecked={inputCheck}
                                />
                                <FormControlLabel
                                    key="false"
                                    value="false"
                                    control={<Radio size="small" />}
                                    label="No"
                                    defaultChecked={inputCheck}
                                />
                                </RadioGroup>
                            </FormControl>
                            </Grid>
                        <Divider />

                        <Card 
                        sx={{ m: 2 , textAlign: 'left', alignItems: 'left', width: '100%'}}
                        
                        >
                    <CardHeader 
                    title="Relaciones"
                    /> 
                        <FormControl
                        sx={{ m: 2, width: '50ch', alignItems: 'left' }}
                        >
                            <FormLabel>
                                Agregar Relacion</FormLabel>
                                <Select
                                lebel="Grupo"
                                name="grupo"
                                lebelId="1"
                                onChange={handleSelectChangeGrupo}
                                
                                >
                                    {items.map((e,i) => {
                                        return (
                                            <MenuItem key={i} value={e.idItem} name={e.nombre}>
                                                {e.nombre}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                        </FormControl>
                        {nuevaRelacionItem.map((e,i) => {
                            return (
                                <CardContent>
                                    <Grid container>
                                        <Grid>
                                            <Box 
                                            sx={{
                                                // some styles
                                                ":hover": {
                                                boxShadow: 1,
                                                },
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                            >
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h6"
                                                >
                                                    {e.nombre}
                                            </Typography>                                         
                                            </Box>  
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                    <CardActions>
                                        <Button
                                            color="error"
                                            variant="text"
                                            type="button"
                                            onClick={() => eliminarNuevaRelacion(e)}
                                        >
                                            Quitar
                                        </Button>
                                    </CardActions>
                                </CardContent>                               
                                )
                        })}
                    <Divider />
                    <br/>
                    <Card>
                    <CardHeader 
                    subheader="Relaciones actuales"
                    sx={{ textAlign: 'left' }}
                    />
                    
                    {grupoDetalle.Relaciones.map((e, i) => {
                        return (
                            <CardContent>
                                <Grid container >
                                    <Grid>
                                        <Box 
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                        <Typography
                                            color="textPrimary"
                                            gutterBottom
                                            variant="h6"
                                            >
                                            {e.Item.nombre}
                                        </Typography> 
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider/>
                                <CardActions>
                                    <Button
                                        color="error"
                                        variant="text"
                                        type="button"
                                        onClick={() => eliminarRelacionExistente(e.idRelacion)}
                                    >
                                        Quitar
                                    </Button>
                                </CardActions>
                            </CardContent>
                        )
                    })}
                    </Card>
                </Card>
                <Divider />
                    <Grid 
                        container 
                        alignItems="space-between" 
                        justify="center" 
                        direction="row" 
                        margin={2}
                        spacing={2}
                    >
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"
                                disabled={errors.nombre || errors.tag ? true : false}
                                >
                                Guardar Cambios
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                    </CardContent>
                    </Card>
                </form>
        </>
    ) : (
        <Loading />
    )
}
