import React from "react";
import Graph from "react-graph-vis";


import './ItemMonitor.css';

export default function ItemMonitor({graph, options, event}) {
  
  return (
    <div className="item-monitor">
      
      <Graph
        graph={graph}
        options={options}
        events={event}
        getNetwork={network => {
          //  if you want access to vis.js network api you can set the state in a parent component using this property
        }}
      />

    </div>
    
  );
}