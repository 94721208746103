import React from 'react'
import Monitor from '../monitor/Monitor'
import { NavBar } from '../navBar/NavBar';
import './Home.css';

// Paguina principal, esta queda abstraida de logica y funcionalidad para implementar 
// componentes tales como una barra de navegacion, login, vista de errores u otra implementacion a considerar en un futuro.

const Home = () => {
    return (
        <div className="home-container">
            <NavBar />
            <Monitor />
        </div>
    )
}

export default Home;
