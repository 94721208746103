import React from 'react';
import {NavLink} from 'react-router-dom';

import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';

import AppsIcon from '@mui/icons-material/Apps';
import AccountCircle from '@mui/icons-material/AccountCircle';

import './navBar.css'
import logoCelta from '../../logo.png'

export const NavBar = () => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar 
                position="static"
                color='transparent'
                >
                <Toolbar>
                    <Button color="inherit" component={NavLink} to='/backoffice' > 
                        <AppsIcon sx={{fontSize: 24}}/> 
                    </Button>

                    <Typography variant="h4" component="div" color="primary" sx={{ flexGrow: 1 }}>
                        <img src={logoCelta} alt="" className='imgNavBar' />
                        
                    </Typography>
                    <Button color="inherit">
                        <AccountCircle sx={{fontSize: 24}} />
                         
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
