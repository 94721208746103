import axios from "axios";

export const obtenerDatosNovedades = async () => {
    let res = await axios.get('notificaciones');
    res = res.data.items;
    let logMonitor = '';
    // fecha res[0].fecha.slice(0,-14) + " - " + res[0].fecha.slice(11, -5)
    // console.log("res model", res)

    res.map((e) => {
      let fecha = e.fecha.split(' ');
      let hora = fecha[1];
      let dia = fecha[0].split('-')
      // eslint-disable-next-line
      return logMonitor = logMonitor + dia[2]+'/'+dia[1]+'/'+dia[0] + ' - ' + hora +  ': '  + e.Estado.tag + ' - ' + e.Item.nombre +   ' / ' + e.mensaje + '\n'
  })
    

  return logMonitor
};
