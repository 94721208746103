const PUBLIC_VAPID_KEY = 'BLwDzxG5Wbp3bdhhboQ_QxylU5I2tLMXYsmuVQPjKvZ3u0I6zMSn5Br2ojt7l2f-LED333JCesKZXMrCz-IUiP4';

const subscription = async () => {

    // Service Worker
    if('serviceWorker' in navigator){
        let registrer;
        try {
            await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/worker.js`, {
                scope: '/'
            }).then(function(registration) {
                registrer = registration;
                var serviceWorker;
                if (registration.installing) {
                    serviceWorker = registration.installing;
                } else if (registration.waiting) {
                    serviceWorker = registration.waiting;
                } else if (registration.active) {
                    serviceWorker = registration.active;
                }
                
                if (serviceWorker) {
                    // console.log("ServiceWorker phase:", serviceWorker.state);
        
                    serviceWorker.addEventListener('statechange', function (e) {
                        console.log("ServiceWorker phase:", e.target.state);
                    });
                }
            })
            .catch(function(err) {
                console.log('Service Worker Error', err);
            });
            
            const subscriptionBody = await registrer.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: PUBLIC_VAPID_KEY
            });
            
            await fetch(process.env.REACT_APP_BASE_URL + '/suscripcion', {
                method: 'POST',
                body: JSON.stringify(subscriptionBody),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        } catch (error) {
            console.log("catch main", error)
        }
    } else {
        console.log("Not service workers...")
    }
    
}

export default subscription;