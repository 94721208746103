import React from "react";
import './App.css';

import {ThemeProvider} from '@mui/material';
import theme from './themeConfig';


import Home from "./components/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Usuarios } from "./components/usuarios/Usuarios";
import BackOffice from "./components/backOffice/BackOffice";
import { Items } from "./components/backOffice/Items";
import { Grupos } from "./components/backOffice/Grupos";
import { ItemsDetalle } from "./components/backOffice/ItemsDetalle";
import { ItemsModificar } from "./components/backOffice/ItemsModificar";
import { GruposDetalle } from "./components/backOffice/GruposDetalle";
import { GruposModificar } from "./components/backOffice/GruposModificar";
import { Notificaciones } from "./components/backOffice/Notificaciones";
import { NotificacionesDetalle } from "./components/backOffice/NotificacionesDetalle";
import { TrafficByDevice } from "./components/backOffice/traffic-by-device";
import { ItemsNuevo } from "./components/backOffice/ItemsNuevo";
import { GruposNuevo } from "./components/backOffice/GruposNuevo";
 


export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Home/>} />
                        <Route path='/usuarios' element={<Usuarios />} />
                        <Route path='/backoffice/*' element={<BackOffice />} >
                            <Route path='' element={<TrafficByDevice />} />
                            <Route path='items/' element={<Items />} />
                            
                            <Route path='items/nuevo' element={<ItemsNuevo />} />
                            <Route path='items/modificar/:tag' element={<ItemsModificar />} />
                            <Route path='items/:tag' element={<ItemsDetalle />} />
                            
                            <Route path='grupos/nuevo' element={<GruposNuevo />} />
                            <Route path='grupos/modificar/:tag' element={<GruposModificar />} />
                            <Route path='grupos/:tag' element={<GruposDetalle />} />

                            <Route path='grupos' element={<Grupos/>} />

                            <Route path='notificaciones' element={<Notificaciones />} />
                            <Route path='notificaciones/:id' element={<NotificacionesDetalle />} />
                            
                            <Route path='configuracion' element={<p>Configuracion Parametros</p>} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div>
        </ThemeProvider>
        
);
}
 