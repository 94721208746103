import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';


import IconCheck from "@mui/icons-material/CheckCircle";
import IconCircle from "@mui/icons-material/Circle"

import AnnouncementIcon from '@mui/icons-material/Announcement';
import mostrarAlerta from "../../hooks/mostrarAlerta";
import { Box, Link } from "@mui/material";

export default function ItemMonitorMobile({data}) {

    const [expanded, setExpanded] = React.useState(false);

    const [alertWatch, setAlertWatch] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let servicios = []; 

    for(let i = 1; i < data.nodes.length; i++){
        servicios.push(data.nodes[i])
        if(data.nodes[i].color === "#c70202" || data.nodes[i].color === "#eb9928") {
            if(alertWatch === false) {
                setAlertWatch(true);
            } 
        }
    }

    return (
        <Box sx={{
            ml:1, 
            mr: 1, 
            mt: 1
        }}>
            <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ color: "text.secondary", mt: 1}}>
                        {
                            data.nodes[0].color === "#c70202" ? 
                                <CancelIcon sx={{ color: data.nodes[0].color }}/> : 
                            data.nodes[0].color === "#eb9928" ? 
                            <ErrorIcon sx={{ color: data.nodes[0].color }}/> : 
                            <IconCheck sx={{ color: data.nodes[0].color }}/>
                        }
                        
                    </Typography>
                    <Typography sx={{ flexGrow: 1, flexShrink: '100%', textAlign: 'left', ml: 2, alignSelf: 'center' }}>
                        {data.nodes[0].label}
                    </Typography>
                    { alertWatch === true ? 
                        <AnnouncementIcon 
                            color="primary"
                            
                            sx={{mr: 2, fontSize: 20, flexShrink: 0, alignSelf: 'center'}}
                            /> : <></>}    
                    
                </AccordionSummary>
                <AccordionDetails>
                    {
                        servicios?.map((e, i) => {
                            
                            return (
                              
                                <Typography key={i} sx={{textAlign: 'left'}}>
                                    
                                    <IconCircle sx={{ 
                                        color: e.color ,
                                        fontSize: 16,
                                        mr: 2
                                    }}/>
            
                                <Link
                                    component="button"
                                    variant="subtitle1"
                                    sx={{
                                        textDecoration: "none"
                                    }}
                                    onClick={() => {
                                        mostrarAlerta(e.id);
                                }}>
                                    {e.label}
                                </Link>
                                   
                                   
                                </Typography>
                             
                                
                            )
                            
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
