import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import axios from 'axios';

import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Box
  } from '@mui/material';
import Loading from '../loading/Loading';


export const GruposDetalle = () => {

    const params = useParams();
    // console.log("params", params);
    const [grupoDetalle, setGrupoDetalle] = useState();
   
    useEffect(() => {
        const getDataGrupoDetalle = async () => {
            try {
                let grupoTagDetalle = await axios.get('grupos/' + params.tag);
                // console.log(itemTagDetalle)
                grupoTagDetalle = grupoTagDetalle.data.items;
                // console.log("2", itemTagDetalle)
                setGrupoDetalle(grupoTagDetalle)
                
            } catch (error) {
                console.log(error)
            }
            
        }
        getDataGrupoDetalle();
    }, [params])

    return grupoDetalle ? (
        < >
            {/* <Typography variant='h3' align="center" sx={{mb:2}} >
                Detalle Grupo
            </Typography> */}

            <Card>
                <CardHeader
                title={grupoDetalle.nombre}
                />
                <Divider />
                <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Nombre"
                        name="nombre"
                        defaultValue={grupoDetalle.nombre}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="TAG"
                        name="tag"
                        defaultValue={grupoDetalle.tag}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Habilitado"
                        name="habilitado"
                        defaultValue={grupoDetalle.habilitado === 1 ? 'SI' : 'NO'}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Estado"
                        name="estado"
                        defaultValue={grupoDetalle.EstadoIdEstado === 1 ? "ok" : grupoDetalle.EstadoIdEstado === 2 ? "warning" : "error"}
                        variant="standard"
                    />
                    </Grid>
                </Grid>
                </CardContent>
            <Divider />
            <Card>
                <CardHeader 
                    subheader="Relaciones actuales"
                    sx={{ textAlign: 'left' }}
                    />
                
                {grupoDetalle.Relaciones.length > 0 ? 
                
                grupoDetalle.Relaciones.map((e,i) => {
                    return (
                        
                        <CardContent
                        key={i}
                        >
                        <Grid container >
                            <Grid>
                                <Box 
                                    sx={{
                                        alignItems: 'left',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="h6"
                                    >
                                    {e.Item.nombre}
                                </Typography> 
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                        
                    )
                }) 
                 : (
                    <Typography>
                        Sin relaciones
                    </Typography>
                )   
                }
                
            </Card>
            <Divider />

        </Card>
        </>
    ) : (
        <Loading/>
    )
}
