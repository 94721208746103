import {Outlet} from 'react-router-dom';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import BackOfficeNavBar from './BackOfficeNavBar';
import Cajon from './Cajon'
import { useState } from 'react';

const estilos = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}))

const BackOffice = () => {

    const classes = estilos();
    const [abrir, setAbrir] = useState(false);

    const accionAbrir = () => {
        setAbrir(!abrir)
    }

    return (
        <div className={classes.root}>
            <BackOfficeNavBar accionAbrir={accionAbrir}/>
            <Box sx={{ display: { sm: 'block', xs: 'none' } }} >
                <Cajon 
                    variant="permanent"
                    open={true}
                />
            </Box>
            <Box >
                <Cajon 
                    variant="temporary"
                    open={abrir}
                    onClose={accionAbrir}
                />
            </Box>
            
            <div className={classes.content}>
                <div className={classes.toolbar}></div>
                
                <Outlet /> 
                
            </div>
        </div>
    )
}

export default BackOffice
