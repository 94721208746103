import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { CardContent, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';


import './items.css';
import Loading from '../loading/Loading';

export const ItemsModificar = () => {
    const params = useParams();
    
    const [reload, setReload] = useState(false)
    const [itemDetalle, setItemDetalle] = useState();
    const [grupos, setGrupos] = useState();
    const [nuevaRelacion, setNuevaRelacion] = useState([]);
    const [eliminarRelacion, setEliminarRelacion] = useState([]);
    const [errors, setErrors] = useState({});

    const [input, setInput] = useState({
        nombre: '',
        tag: '',
        url_chequeo: '',
        tamanio: '',
        id: '',
        estado: ''
    });

    const [inputCheck, setInputCheck] = useState();

    useEffect(() => {
        const getDataItemDetalle = async () => {
            try {
                let itemTagDetalle = await axios.get('items/' + params.tag);
                itemTagDetalle = itemTagDetalle.data.items;
          
                let allGrupos = await axios.get('grupos');
                allGrupos = allGrupos.data.items;

                setGrupos(allGrupos);

                setItemDetalle(itemTagDetalle);
                
                setInput({
                    nombre: itemTagDetalle.nombre,
                    tag: itemTagDetalle.tag,
                    url_chequeo: itemTagDetalle.url_chequeo,
                    tamanio: itemTagDetalle.tamanio,
                    id: itemTagDetalle.id,
                    estado: itemTagDetalle.EstadoIdEstado
                });
                if(itemTagDetalle.habilitado === 1){
                    setInputCheck(true)
                } else {
                    setInputCheck(false)
                }

                setReload(true)
                return 
                
            } catch (error) {
                console.log(error)
            }
            
        }
        getDataItemDetalle();
    }, [params, reload]);

    async function modificarItem () {
        let checkHabilitado = inputCheck === true ? 1 : 0;
        // let checkAfecta_padre = inputCheckAfecta === true ? 1 : 0
        let bodyModificacion = {
            nombre: input.nombre,
            habilitado: checkHabilitado,
            tag: input.tag,
            tamanio: input.tamanio,
            id: itemDetalle.idItem,
            estado: input.estado,
            url_chequeo: input.url_chequeo
        }
        // console.log("body modificar", bodyModificacion)
        await axios.put('items', bodyModificacion)
        await agregarRelacionItem(nuevaRelacion)
        await quitarRelacion(eliminarRelacion)
        // console.log(resModificar)
        setReload(false)
        return true
    }


    async function handleSubmit(e){
        e.preventDefault();
        // dispatch a ruta de back
        // console.log("input", input)
        let res = await modificarItem(input);
        if(res === true) {
            alert("Item Modificado") 
        }
        
        return
    }

    function handleInputChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSelectChange(e){
        setInput({
            ...input,
            tamanio: e.target.value
        })
    }

    function handleSelectChangeGrupo(e, i) {
        // console.log("e", e)
        // console.log("i", i.props.name)
        setNuevaRelacion([
            ...nuevaRelacion,
            {
                id: e.target.value,
                nombre: i.props.name
            }
        ])
        console.log("nuevaRel", nuevaRelacion)
    }

    function handleSelectChangeEstado(e){
        setInput({
            ...input,
            estado: e.target.value
        })
    }

    function handleInputChangeCheck(e){
        setInputCheck(!inputCheck)
        // console.log("hadle", e.target.value)
        
    }

    async function quitarRelacion (idCards) {
        
        async function eliminar(idCard) {
            await axios.delete('relaciones', {data: {id: idCard}});
            return
        }
        
        idCards.forEach(relacion => eliminar(relacion))
        return
    }

    async function agregarRelacionItem (nuevaRel) {

        async function agregar(relacion) {
            await axios.post('relaciones', relacion)
            // console.log(resAgregarRelacionItem)
            return
        }

        let dataNuevaRel = nuevaRel.map((e) => {
            return {
                idGrupo: e.id, 
                afecta_padre: 1, 
                idItem: itemDetalle.idItem
            }
        })

        dataNuevaRel.forEach(relacion => agregar(relacion))
        
        
    }

    function eliminarNuevaRelacion(e) {
        let newData = nuevaRelacion.filter(n => n !== e)
        setNuevaRelacion(newData)
    }
    
    function eliminarRelacionExistente(e) {
        setEliminarRelacion([
            ...eliminarRelacion, e
        ])
        let relacionesModificadas = itemDetalle.Relacion.filter(r => r.idRelacion !== e)
        // console.log("??", itemDetalle.Relacion)
        setItemDetalle({
            ...itemDetalle,
            Relacion: relacionesModificadas
        })
    }

    function validarCampos (input) {
        let errors = {};

        let regexTag = /^[a-zA-Z-0-9]+$/;
        let regexUrl = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;

        if(!input.nombre.trim()) {
            errors.nombre = " * Nombre es requerido"
        }

        if(!input.tag.trim()) {
            errors.tag = " * Tag es requerido"
        } else if(!regexTag.test(input.tag.trim())) {
            errors.tag = " * El campo TAG no permite caracteres especiales ni espacios!"
        }

        if(!input.url_chequeo.trim()) {
            errors.url_chequeo = " * Tag es requerido"
        } else if(!regexUrl.test(input.url_chequeo.trim())) {
            errors.url_chequeo = " * Debe ser una URL valida"
        }

        if(!input.url_chequeo.trim()) {
            errors.url_chequeo = " * URL es requerido"
        }

        return errors;
    }

    function handleOnBlur (e) {
        
        setErrors(validarCampos(input))
    }

    return reload ? (
        <>
            <Typography variant='h3' align="center" sx={{mb:2}} >
                Modificar Item
            </Typography>
            <Divider/>
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardHeader 
                        title={itemDetalle.nombre}
                        />
                        <Divider />
                        <CardContent>
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    
                    >
                            <Grid 
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                name="nombre"
                                label="Nombre"
                                type="text"
                                value={input.nombre}
                                placeholder={itemDetalle.nombre} 
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                autoComplete="off"
                                fullWidth={true} 
                                
                                helperText={errors.nombre && errors.nombre}
                                error={errors.nombre && true}
                            />
                            </Grid>
                            <Grid 
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                label="TAG"
                                type="text" 
                                name="tag" 
                                placeholder={itemDetalle.tag} 
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                value={input.tag} 
                                className="input"
                                autoComplete="off"
                                fullWidth
                                
                                helperText={errors.tag && errors.tag}
                                error={errors.tag && true}
                            />
                            </Grid>
                            <Grid 
                            item
                            md={12}
                            xs={12}
                            >
                            <TextField
                                label="URL_Chequeo"
                                type="text" 
                                name="url_chequeo" 
                                placeholder={itemDetalle.url_chequeo} 
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                value={input.url_chequeo} 
                                className="input"
                                autoComplete="off"
                                fullWidth
                                
                                helperText={errors.url_chequeo && errors.url_chequeo}
                                error={errors.url_chequeo && true}
                            />
                            </Grid>
                        
                            <Grid
                            item
                            md={6}
                            xs={12}
                            
                            >
                                <FormControl
                                sx={{display:"flex", alignItems: "flex-start", textAlign: "initial"}}
                                >
                                <FormLabel>Tamaño</FormLabel>
                                <Select
                                name="tamanio"
                                value={input.tamanio}
                                onChange={handleSelectChange}
                                defaultValue={itemDetalle.tamanio}
                                fullWidth
                                
                                >
                                <MenuItem key={8} value="8">
                                    8
                                </MenuItem>
                                <MenuItem key={10} value="10">
                                    10
                                </MenuItem>
                                <MenuItem key={12} value="12">
                                    12
                                </MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            
                            
                            <Grid 
                            item
                            md={6}
                            xs={12}
                            
                            >
                            <FormControl
                            sx={{display:"flex",alignItems: "flex-start", textAlign: "initial"}}
                            >
                                <FormLabel>Estado</FormLabel>
                                <Select
                                name="estado"
                                value={input.estado}
                                onChange={handleSelectChangeEstado}
                                defaultValue={itemDetalle.EstadoIdEstado}
                                fullWidth
                                
                                >
                                <MenuItem key={1} value="1">
                                    OK
                                </MenuItem>
                                <MenuItem key={2} value="2">
                                    Warning
                                </MenuItem>
                                <MenuItem key={3} value="3">
                                    Error
                                </MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            
                            <Grid item
                            
                            md={12}
                            xs={12}
                        
                            sx={{display:"flex", margin: 2}}
                            >
                            <FormControl>
                                <FormLabel>Habilitado</FormLabel>
                                <RadioGroup
                                
                                name="check" 
                                onChange={handleInputChangeCheck}
                                value={inputCheck}
                                fullWidth
                                
                                row
                                >
                                <FormControlLabel
                                    key="true"
                                    value="true"
                                    control={<Radio size="small" />}
                                    label="Si"
                                    defaultChecked={inputCheck}
                                />
                                <FormControlLabel
                                    key="false"
                                    value="false"
                                    control={<Radio size="small" />}
                                    label="No"
                                    defaultChecked={inputCheck}
                                />
                                </RadioGroup>
                            </FormControl>
                            </Grid>

                        <Divider/>
                <Card
                sx={{ m: 2, textAlign: 'left', alignItems: 'left', width: '100%'}}
                >
                    <CardHeader 
                    title="Relaciones"
                    />
                            <FormControl
                            sx={{ m: 2, width: '50ch', alignItems: 'left' }}
                            >
                                <FormLabel
                                    
                                >
                                    Agregar Relacion</FormLabel>
                                    <Select
                                    lebel="Grupo"
                                    name="grupo"
                                    lebelid="relaciones"
                                    onChange={handleSelectChangeGrupo}
                                    labelId="grupo"
                                    >
                                        {grupos.map((e,i) => {
                                            return (
                                                <MenuItem key={i} value={e.idGrupo} name={e.nombre}>
                                                    {e.nombre}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                            </FormControl>
                            {nuevaRelacion.map((e,i) => {
                                return (
                                                                      
                                    <CardContent key={i}>
                                        <Grid container >
                                            <Grid>
                                                <Box 
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                <Typography
                                                    color="textPrimary"
                                                    gutterBottom
                                                    variant="h6"
                                                    >
                                                    {e.nombre}
                                                </Typography> 
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider/>
                                        <CardActions>
                                            <Button
                                                color="error"
                                                variant="text"
                                                type="button"
                                                onClick={() => eliminarNuevaRelacion(e)}
                                            >
                                                Quitar
                                            </Button>
                                        </CardActions>
                                    </CardContent>
                                    )
                            })}
                    <Divider />
                    <br/>
                    <Card >
                    <CardHeader 
                    subheader="Relaciones actuales"
                    sx={{ textAlign: 'left' }}
                    />
                    
                    {itemDetalle.Relacion.map((e, i) => {
                        return (
                            <CardContent
                            key={`${i}`}
                            >
                                <Grid container >
                                    <Grid>
                                        <Box 
                                            sx={{
                                                alignItems: 'left',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                        <Typography
                                            color="textPrimary"
                                            gutterBottom
                                            variant="h6"
                                            >
                                            {e.Grupo.nombre}
                                        </Typography> 
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider/>
                                <CardActions>
                                    <Button
                                        color="error"
                                        variant="text"
                                        type="button"
                                        onClick={() => eliminarRelacionExistente(e.idRelacion)}
                                    >
                                        Quitar
                                    </Button>
                                </CardActions>
                            </CardContent>
                        )
                    })}
                </Card>
                </Card>   
                    <Grid 
                        container 
                        alignItems="space-between" 
                        justify="center" 
                        direction="row" 
                        margin={2}
                        spacing={2}
                    >
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit" 
                                disabled={errors.nombre || errors.tag || errors.url_chequeo ? true : false}
                                >
                                Guardar Cambios
                            </Button>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </CardContent>
            </Card>
            </form>
        </>
    ) : (
        <Loading />
    )
}
