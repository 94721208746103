import React from 'react';

import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {makeStyles} from '@mui/styles';

import {Menu} from '@mui/icons-material'

const useStyles = makeStyles(theme => ({
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    title: {
      flexGrow: 1
    },
    appBar: {
      [theme.breakpoints.up('sm')] : {
        width: `calc(100% - ${240}px)`,
        marginLeft: 240
      }
    }
  }))

const BackOfficeNavBar = (props) => {

    const classes = useStyles();
  
    return (
        <AppBar className={classes.appBar} color="default">
            <Toolbar>
                <IconButton
                    color="primary" 
                    aria-label="menu" 
                    className={classes.menuButton}
                    onClick={() => props.accionAbrir()}
                >

                <Menu/>

                </IconButton>
                
                <Typography variant='h6' className={classes.title}>
                    BackOffice
                </Typography>
                <Button variant='text' color='secondary'>
                    Login
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default BackOfficeNavBar;