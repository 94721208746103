import {Link} from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'

import DashboardIcon from '@mui/icons-material/Dashboard';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
// import SettingsIcon from '@mui/icons-material/Settings';



const BackOfficeMenu = () => {
    const active = false
    return (
        <div>
            <List component="nav" >
                <ListItem >
                <Button
                    component={Link} to=''
                    startIcon={<DashboardIcon />}
                    disableRipple
                    sx={{
                        backgroundColor: active && 'rgba(255,255,255, 0.08)',
                        borderRadius: 1,
                        color: active ? 'secondary.main' : 'neutral.300',
                        fontWeight: active && 'fontWeightBold',
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                        color: active ? 'secondary.main' : 'neutral.400'
                        },
                        '&:hover': {
                        backgroundColor: 'rgba(255,255,255, 0.18)'
                        }
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        DashBoard
                    </Box>
                </Button>
                </ListItem>

                <ListItem>
                    <Button
                        component={Link} to='items'
                        startIcon={<ChangeHistoryIcon />}
                        disableRipple
                        sx={{
                            backgroundColor: active && 'rgba(255,255,255, 0.08)',
                            borderRadius: 1,
                            color: active ? 'secondary.main' : 'neutral.300',
                            fontWeight: active && 'fontWeightBold',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                            color: active ? 'secondary.main' : 'neutral.400'
                            },
                            '&:hover': {
                            backgroundColor: 'rgba(255,255,255, 0.18)'
                            }
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            Items
                        </Box>
                    </Button>
                </ListItem>

                <ListItem >
                    <Button
                        component={Link} to='grupos'
                        startIcon={<GroupWorkIcon/>}
                        disableRipple
                        sx={{
                            backgroundColor: active && 'rgba(255,255,255, 0.08)',
                            borderRadius: 1,
                            color: active ? 'secondary.main' : 'neutral.300',
                            fontWeight: active && 'fontWeightBold',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                            color: active ? 'secondary.main' : 'neutral.400'
                            },
                            '&:hover': {
                            backgroundColor: 'rgba(255,255,255, 0.18)'
                            }
                        }}
                        >
                        <Box sx={{ flexGrow: 1 }}>
                            Grupos
                        </Box>
                    </Button>
                </ListItem>

                <ListItem>
                    <Button
                        component={Link} to='notificaciones'
                        startIcon={<CircleNotificationsIcon/>}
                        disableRipple
                        sx={{
                            backgroundColor: active && 'rgba(255,255,255, 0.08)',
                            borderRadius: 1,
                            color: active ? 'secondary.main' : 'neutral.300',
                            fontWeight: active && 'fontWeightBold',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                            color: active ? 'secondary.main' : 'neutral.400'
                            },
                            '&:hover': {
                            backgroundColor: 'rgba(255,255,255, 0.18)'
                            }
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            Notificaciones
                        </Box>
                    </Button>
                </ListItem>

                <Divider/>

                {/* <ListItem button component={Link} to='configuracion'>
                <Button
                        component={Link} to='configuracion'
                        startIcon={<SettingsIcon/>}
                        disableRipple
                        sx={{
                            backgroundColor: active && 'rgba(255,255,255, 0.08)',
                            borderRadius: 1,
                            color: active ? 'secondary.main' : 'neutral.300',
                            fontWeight: active && 'fontWeightBold',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                            color: active ? 'secondary.main' : 'neutral.400'
                            },
                            '&:hover': {
                            backgroundColor: 'rgba(255,255,255, 0.18)'
                            }
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            Configuracion Parametros
                        </Box>
                    </Button>
                </ListItem> */}
            </List>
        </div>
    )
}

export default BackOfficeMenu
